























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Product from '@/data/models/product';
import { capitalizeFirstLetter, formatPrice } from '@/helpers/format-string';
import Button from '@/components/commons/Button.vue';
import Relay from '@/data/models/relay';
import Lottie from '@/components/commons/Lottie.vue';
import { ADD_TO_CART, REMOVE_FROM_CART, SELECT_RELAY, REMOVE_ALL_FROM_CART } from '@/store/mutation-types';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { EventType, trackEvent } from '@/helpers/analytics';
import OrderWithDetails from '@/data/models/order-with-details';

@Component({
   components: { Button, lottie: Lottie },
   props: {
      orderWithDetails: {
         default: null,
         type: Object as () => OrderWithDetails
      },
      submitted: {
         default: false,
         type: Boolean
      }
   }
})
export default class CartProducts extends Vue {
   relays: Relay[] = [];
   selectedRelay: Relay = this.$store.state.selectedRelay;
   isLoading: boolean = false;

   mounted() {
      this.$service.relays.getAllRelays().then(relays => (this.relays = relays));
   }

   @Watch('selectedRelay', { immediate: true })
   onIsSelectedRelay() {
      if (this.selectedRelay) {
         trackEvent(EventType.CART_SELECT_RELAY, { relay_name: this.selectedRelay.name, relay_id: this.selectedRelay.id });
      }

      this.$store.commit(SELECT_RELAY, this.selectedRelay);
   }

   get relaysItem(): { value: Relay; text: string }[] {
      return this.relays.map(relay => {
         return { value: relay, text: relay.name };
      });
   }

   get relay() {
      return Relay.map(this.$props.orderWithDetails.relay);
   }

   get relayValidator(): string {
      return this.$props.submitted && this.selectedRelay === null ? this.$t('validators.relay').toString() : '';
   }

   get products() {
      const orderWithDetails = OrderWithDetails.map(this.$props.orderWithDetails);
      if (orderWithDetails) {
         return orderWithDetails.products;
      } else {
         return this.$store.state.cart;
      }
   }

   formatPrice(price: number) {
      return formatPrice(price);
   }

   capitalizeFirstLetter(string: string): string {
      return capitalizeFirstLetter(string);
   }

   removeFromCart(product: Product) {
      this.$store.commit(REMOVE_FROM_CART, product);
      trackEvent(EventType.CART_TAP_LESS_PRODUCT, { product_name: product.name, product_id: product.id });
   }

   removeAllFromCart(product: Product) {
      this.$store.commit(REMOVE_ALL_FROM_CART, product);
      trackEvent(EventType.CART_TAP_DELETE_PRODUCT, { product_name: product.name, product_id: product.id });
   }

   addToCart(product: Product) {
      this.$store.commit(ADD_TO_CART, product);
      trackEvent(EventType.CART_TAP_MORE_PRODUCT, { product_name: product.name, product_id: product.id });
   }

   getIcon(product: Product) {
      const _product = Product.map(product.id, product);
      const path = _product.getIcon();

      return path;
   }
}
