import firebase from 'firebase/app';

export enum EventType {
   // HOME
   HOME_SELECT_ADDRESS = 'home_select_address',
   HOME_SELECT_RELAY = 'home_select_relay',
   HOME_ADD_PRODUCT_TO_CART = 'home_add_product_to_cart',
   HOME_CLICK_ADD_TO_CART = 'home_click_add_to_cart',

   // TRACKING
   TRACKING_GET_ORDER_DETAILS = 'tracking_get_order_details',
   TRACKING_SHOW_ORDER_SUCCESS = 'tracking_show_order_success',

   // CART
   CART_SELECT_RELAY = 'cart_select_relay',
   CART_TAP_MORE_PRODUCT = 'cart_tap_more_product',
   CART_TAP_LESS_PRODUCT = 'cart_tap_less_product',
   CART_TAP_DELETE_PRODUCT = 'cart_tap_delete_product',
   CART_BEGIN_CHECKOUT = 'cart_begin_checkout',
   CART_TAP_PAY = 'cart_tap_pay',
   CART_PURCHASE = 'cart_purchase'
}

export enum EventParam {
   PLACE = 'place',
   RELAY_NAME = 'relay_name',
   RELAY_ID = 'relay_id',
   PRODUCT_NAME = 'product_name',
   PRODUCT_ID = 'product_id',
   ORDER_ID = 'order_id',
   CURRENCY = 'currency',
   VALUE = 'value',
   PRODUCTS = 'products'
}

type EventParamType = { [key in EventParam]?: any };

export const trackEvent = (event: EventType, params?: EventParamType) => {
   firebase.analytics().logEvent(event, params);
};
